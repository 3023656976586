import EmergeMaterial from "./EmergeMaterial";
import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useLayoutEffect } from "react";
import * as THREE from "three";
import useScreenSize from "./useScreenSize";
import { OrbitControls, View } from "@react-three/drei";

// 調整 PIXELS 陣列，增加解析度
const PIXELS = [
  1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 
  0.09, 0.08, 0.07, 0.06, 0.05, 0.04, 0.03, 0.02, 0.01
];

export default function EmergingImage({ ...props }) {
  const [hasEmerged, setHasEmerged] = useState(false);
  const [refMesh, setRefMesh] = useState(null);
  const [texture, setTexture] = useState(null);
  const [textureSize, setTextureSize] = useState([0, 0]);
  const [elementSize, setElementSize] = useState([0, 0]);
  const ref = useRef();
  const screenSize = useScreenSize();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.loadAsync(props.url).then((data) => {
      // 設置紋理參數以提高品質
      data.minFilter = THREE.LinearFilter;
      data.magFilter = THREE.LinearFilter;
      data.generateMipmaps = true;
      data.anisotropy = 16; // 增加各向異性過濾
      
      setTextureSize([data.source.data.width, data.source.data.height]);
      setTexture(data);
    });
  }, [props.url]);

  useEffect(() => {
    if(refMesh) {
      refMesh.material.uProgress = 0;
      refMesh.material.uType = props.type;
      
      // 設置材質參數
      refMesh.material.transparent = true;
      refMesh.material.depthWrite = false;
      refMesh.material.side = THREE.DoubleSide;
    }
  }, [props.type, refMesh]);

  const updateMeshSize = () => {
    if (ref.current && refMesh) {
      const bounds = ref.current.getBoundingClientRect();
      const width = bounds.width;
      const height = bounds.height;
      
      // 確保尺寸是正確的
      if (width > 0 && height > 0) {
        setElementSize([width, height]);
        refMesh.scale.set(width, height, 1);
      }
    }
  };

  useLayoutEffect(() => {
    if (refMesh) {
      updateMeshSize();
      
      resizeObserverRef.current = new ResizeObserver(updateMeshSize);
      resizeObserverRef.current.observe(ref.current);
      
      const intersectionObserver = new IntersectionObserver(([entry]) => {
        if (!hasEmerged) {
          setIsIntersecting(entry.isIntersecting);
        }
      });
      
      intersectionObserver.observe(ref.current);
      
      return () => {
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
        intersectionObserver.disconnect();
      };
    }
  }, [refMesh]);

  useGSAP(() => {
    if (refMesh?.material && isIntersecting && !hasEmerged) {
      gsap.to(refMesh.material, {
        uProgress: 1,
        duration: 1.5,
        ease: "none",
        onComplete: () => {
          setHasEmerged(true);
        }
      });
    }
  }, [isIntersecting, props.type, hasEmerged, refMesh]);

  useEffect(() => {
    updateMeshSize();
  }, [screenSize]);

  return (
    <View {...props} ref={ref}>
      <mesh ref={setRefMesh}>
        <emergeMaterial
          uFillColor={new THREE.Color('#403fb7')}
          transparent={true}
          uTexture={texture}
          uPixels={PIXELS}
          uTextureSize={new THREE.Vector2(textureSize[0], textureSize[1])}
          uElementSize={new THREE.Vector2(elementSize[0], elementSize[1])}
        />
        <planeGeometry args={[1, 1]} />
      </mesh>
    </View>
  );
}