import { Canvas, useLoader, useThree, useFrame, createPortal } from '@react-three/fiber'
import { Physics, useCircle, usePlane } from '@react-three/p2'
import { useMemo, useEffect, useState, useRef } from 'react'
import { TextureLoader } from 'three'
import * as THREE from 'three'
import { useMediaQuery } from 'react-responsive'
import { useFBO, useGLTF, useScroll, Text, Image, Scroll, Preload, ScrollControls, MeshTransmissionMaterial } from '@react-three/drei'
import { easing } from 'maath'

const SIZES = {
  desktop: {
    small: { radius: 0.25 },
    medium: { radius: 0.35 },
    large: { radius: 0.4 }
  },
  tablet: {
    small: { radius: 0.2 },
    medium: { radius: 0.3 },
    large: { radius: 0.35 }
  },
  mobile: {
    small: { radius: 0.15 },
    medium: { radius: 0.225 },
    large: { radius: 0.3 }
  }
}

function Circle({ position, size, imageIndex, deviceType }) {
  const imagePath = `/archive/archive${imageIndex}.webp`
  const texture = useLoader(TextureLoader, imagePath)
  const { radius } = SIZES[deviceType][size]
  
  const [ref] = useCircle(() => ({
    mass: 0.1,
    position: position,
    args: [radius],
    restitution: 0.95,
    friction: 0.05,
    damping: 0.01,
    angularDamping: 0.01
  }))
  
  const material = useMemo(() => {
    if (!texture.image) return null;
    
    const canvas = document.createElement('canvas');
    const size = Math.max(texture.image.width, texture.image.height);
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    
    ctx.beginPath();
    ctx.arc(size/2, size/2, size/2, 0, Math.PI * 2);
    ctx.closePath();
    ctx.clip();
    
    const scale = size / Math.min(texture.image.width, texture.image.height);
    const width = texture.image.width * scale;
    const height = texture.image.height * scale;
    const x = (size - width) / 2;
    const y = (size - height) / 2;
    
    ctx.drawImage(texture.image, x, y, width, height);
    
    const newTexture = new THREE.CanvasTexture(canvas);
    newTexture.colorSpace = THREE.SRGBColorSpace;  // Updated from encoding
    
    const mat = new THREE.MeshBasicMaterial({
      map: newTexture,
      transparent: true,
      opacity: 1,
      side: THREE.DoubleSide,
    });
    
    return mat;
  }, [texture]);

  return (
    <mesh ref={ref}>
      <circleGeometry args={[radius, 32]} />
      {material && <primitive object={material} attach="material" />}
    </mesh>
  )
}


function Lens({ children, damping = 0.15, ...props }) {
  const ref = useRef()
  const { nodes } = useGLTF('/lens-transformed.glb')
  const buffer = useFBO({
    alpha: true,
    stencilBuffer: false,
    colorSpace: THREE.SRGBColorSpace,  // Updated from encoding
    samples: 4
  })
  
  const viewport = useThree((state) => state.viewport)
  
  const [scene] = useState(() => {
    const newScene = new THREE.Scene()
    const textureLoader = new THREE.TextureLoader()
    const backgroundTexture = textureLoader.load('/bg-desk.jpeg', (texture) => {
      texture.minFilter = THREE.LinearFilter
      texture.magFilter = THREE.LinearFilter
      texture.colorSpace = THREE.SRGBColorSpace  // Updated from colorSpace
      
      const imageAspect = texture.image.width / texture.image.height
      
      const updateBackgroundSize = () => {
        const canvasAspect = window.innerWidth / window.innerHeight
        
        if (imageAspect > canvasAspect) {
          texture.offset.x = (1 - canvasAspect / imageAspect) / 2
          texture.repeat.x = canvasAspect / imageAspect
          texture.offset.y = 0
          texture.repeat.y = 1
        } else {
          texture.offset.x = 0
          texture.repeat.x = 1
          texture.offset.y = (1 - imageAspect / canvasAspect) / 2
          texture.repeat.y = imageAspect / canvasAspect
        }
      }
  
      const parameters = {
        updateBackgroundSize
      }
      texture.userData = parameters
  
      updateBackgroundSize()
  
      window.addEventListener('resize', updateBackgroundSize)
    })
    
    newScene.background = backgroundTexture
    return newScene
  })
  
  useFrame((state, delta) => {
    const viewport = state.viewport.getCurrentViewport(state.camera, [0, 0, 15])
    easing.damp3(
      ref.current.position,
      [(state.pointer.x * viewport.width) / 2, (state.pointer.y * viewport.height) / 2, 15],
      damping,
      delta
    )
    
    state.gl.setRenderTarget(buffer)
    state.gl.clear(true, true, true)
    state.gl.toneMappingExposure = 1
    state.gl.render(scene, state.camera)
    state.gl.setRenderTarget(null)
  })
  
  return (
    <>
      {createPortal(children, scene)}
      <mesh scale={[viewport.width, viewport.height, 1]}>
        <planeGeometry />
        <meshBasicMaterial
          map={buffer.texture}
          transparent={false}
          toneMapped={true}
          colorSpace={THREE.SRGBColorSpace}  // Updated from encoding
        />
      </mesh>
      <mesh
        scale={0.15}
        ref={ref}
        rotation-x={Math.PI / 2}
        geometry={nodes.Cylinder.geometry}
        {...props}
      >
        <MeshTransmissionMaterial
          buffer={buffer.texture}
          ior={1.2}
          thickness={1.5}
          anisotropy={0.1}
          chromaticAberration={0.04}
          transparent={true}
          opacity={0.9}
          clearcoatRoughness={0.1}
        />
      </mesh>
    </>
  )
}

// function Circle({ position, size, imageIndex, deviceType }) {
//   const imagePath = `/archive/archive${imageIndex}.webp`
//   const texture = useLoader(TextureLoader, imagePath)
//   const { radius } = SIZES[deviceType][size]
  
//   const [ref] = useCircle(() => ({
//     mass: 0.1,
//     position: position,
//     args: [radius],
//     restitution: 0.95,
//     friction: 0.05,
//     damping: 0.01,
//     angularDamping: 0.01
//   }))
  
//   const material = useMemo(() => {
//     if (!texture.image) return null;
    
//     const canvas = document.createElement('canvas');
//     const size = Math.max(texture.image.width, texture.image.height);
//     canvas.width = size;
//     canvas.height = size;
//     const ctx = canvas.getContext('2d');
    
//     ctx.beginPath();
//     ctx.arc(size/2, size/2, size/2, 0, Math.PI * 2);
//     ctx.closePath();
//     ctx.clip();
    
//     const scale = size / Math.min(texture.image.width, texture.image.height);
//     const width = texture.image.width * scale;
//     const height = texture.image.height * scale;
//     const x = (size - width) / 2;
//     const y = (size - height) / 2;
    
//     ctx.drawImage(texture.image, x, y, width, height);
    
//     const newTexture = new THREE.CanvasTexture(canvas);
//     newTexture.encoding = THREE.sRGBEncoding;
    
//     const mat = new THREE.MeshBasicMaterial({
//       map: newTexture,
//       transparent: true,
//       opacity: 1,
//       side: THREE.DoubleSide,
//     });
    
//     return mat;
//   }, [texture]);

//   return (
//     <mesh ref={ref}>
//       <circleGeometry args={[radius, 32]} />
//       {material && <primitive object={material} attach="material" />}
//     </mesh>
//   )
// }

function Circles({ deviceType }) {
  const circleConfigs = useMemo(() => {
    const sizes = ['small', 'medium', 'large']
    const totalImages = 38

    return Array.from({ length: totalImages }, (_, index) => ({
      position: [
        -12 + Math.random() * 24,
        -8 + Math.random() * 16
      ],
      size: sizes[Math.floor(Math.random() * sizes.length)],
      imageIndex: index + 1
    }))
  }, [])

  return (
    <>
      {circleConfigs.map((config, index) => (
        <Circle
          key={index}
          position={config.position}
          size={config.size}
          imageIndex={config.imageIndex}
          deviceType={deviceType}
        />
      ))}
    </>
  )
}

function Plane({ position, angle, size }) {
  const [ref] = usePlane(() => ({
    mass: 0,
    position,
    angle,
    restitution: 0.9
  }))

  return null
}

function Planes() {
  const { viewport } = useThree()
  const viewportKey = `${viewport.width}-${viewport.height}`
  
  const planes = [
    { position: [0, -viewport.height/2], angle: 0, size: viewport.width * 2 },
    { position: [0, viewport.height/2], angle: Math.PI, size: viewport.width * 2 },
    { position: [-viewport.width/2, 0], angle: -Math.PI / 2, size: viewport.height * 2 },
    { position: [viewport.width/2, 0], angle: Math.PI / 2, size: viewport.height * 2 }
  ]

  return (
    <>
      {planes.map((plane, index) => (
        <Plane
          key={`${viewportKey}-${index}`}
          position={plane.position}
          angle={plane.angle}
          size={plane.size}
        />
      ))}
    </>
  )
}

export default function PhysicsScene() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 640px) and (max-width: 1023px)' });
  
  const deviceType = useMemo(() => {
    if (isDesktop) return 'desktop';
    if (isTablet) return 'tablet';
    return 'mobile';
  }, [isDesktop, isTablet]);

  // function Lens({ children, damping = 0.15, ...props }) {
  //   const ref = useRef()
  //   const { nodes } = useGLTF('/lens-transformed.glb')
  //   const buffer = useFBO({
  //     alpha: true,
  //     stencilBuffer: false,
  //     encoding: THREE.sRGBEncoding,
  //     samples: 4
  //   })
    
  //   const viewport = useThree((state) => state.viewport)
    
  //   const [scene] = useState(() => {
  //     const newScene = new THREE.Scene()
  //     const textureLoader = new THREE.TextureLoader()
  //     const backgroundTexture = textureLoader.load('/bg-desk.jpeg', (texture) => {
  //       texture.minFilter = THREE.LinearFilter
  //       texture.magFilter = THREE.LinearFilter
  //       texture.colorSpace = 'srgb'
        
      
  //       const imageAspect = texture.image.width / texture.image.height
        
  //       const updateBackgroundSize = () => {
  //         const canvasAspect = window.innerWidth / window.innerHeight
          
  //         if (imageAspect > canvasAspect) {
  //           texture.offset.x = (1 - canvasAspect / imageAspect) / 2
  //           texture.repeat.x = canvasAspect / imageAspect
  //           texture.offset.y = 0
  //           texture.repeat.y = 1
  //         } else {
  //           texture.offset.x = 0
  //           texture.repeat.x = 1
  //           texture.offset.y = (1 - imageAspect / canvasAspect) / 2
  //           texture.repeat.y = imageAspect / canvasAspect
  //         }
  //       }
    
  //       const parameters = {
  //         updateBackgroundSize
  //       }
  //       texture.userData = parameters
    
  //       updateBackgroundSize()
    
  //       window.addEventListener('resize', updateBackgroundSize)
  //     })
      
  //     newScene.background = backgroundTexture
  //     return newScene
  //   })
    
  //   useFrame((state, delta) => {
  //     const viewport = state.viewport.getCurrentViewport(state.camera, [0, 0, 15])
  //     easing.damp3(
  //       ref.current.position,
  //       [(state.pointer.x * viewport.width) / 2, (state.pointer.y * viewport.height) / 2, 15],
  //       damping,
  //       delta
  //     )
      
  //     state.gl.setRenderTarget(buffer)
  //     state.gl.clear(true, true, true)
  //     state.gl.toneMappingExposure = 1
  //     state.gl.render(scene, state.camera)
  //     state.gl.setRenderTarget(null)
  //   })
    
  //   return (
  //     <>
  //       {createPortal(children, scene)}
  //       <mesh scale={[viewport.width, viewport.height, 1]}>
  //         <planeGeometry />
  //         <meshBasicMaterial
  //           map={buffer.texture}
  //           transparent={false}
  //           toneMapped={true}
  //           colorSpace={THREE.sRGBEncoding}
  //         />
  //       </mesh>
  //       <mesh
  //         scale={0.15}
  //         ref={ref}
  //         rotation-x={Math.PI / 2}
  //         geometry={nodes.Cylinder.geometry}
  //         {...props}
  //       >
  //         <MeshTransmissionMaterial
  //           buffer={buffer.texture}
  //           ior={1.2}
  //           thickness={1.5}
  //           anisotropy={0.1}
  //           chromaticAberration={0.04}
  //           transparent={true}
  //           opacity={0.9}
  //           clearcoatRoughness={0.1}
  //         />
  //       </mesh>
  //     </>
  //   )
  // }

  return (
    <Canvas camera={{ position: [0, 0, 20], fov: 15 }}>
      {(isDesktop || isTablet) ? (
        <Lens>
          <Physics
            normalIndex={2}
            gravity={[0, -0.5]}
            iterations={8}
          >
            <Circles deviceType={deviceType} />
            <Planes />
          </Physics>
          <Preload />
        </Lens>
      ) : (
        <>
          <Physics
            normalIndex={2}
            gravity={[0, -0.5]}
            iterations={8}
          >
            <Circles deviceType={deviceType} />
            <Planes />
          </Physics>
          <Preload />
        </>
      )}
    </Canvas>
  )
}