import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import EmergingImage from './EmergingImage'
import Scene from './Scene'
import StackGrid, { transitions } from 'react-stack-grid';
import { useMediaQuery } from 'react-responsive';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const transition = transitions.scaleDown;



const Archive = () => {

  
  const isDesktop = useMediaQuery({
    query: '(min-width: 640px)',
  });
  const gridItemsRef = useRef([]);

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const getRandomSize = () => {
    const minSize = 250;
    const maxSize = 400;
    return Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
  };



  const getEmptyItem = () => ({
    isEmpty: true,
    height: getRandomSize()
  });




  // 圖片數組使用 useMemo 優化
  const images = useMemo(() => {
    const baseImages = shuffleArray([
      { src: '/images/nul1org_10th_1.jpeg' },
      { src: '/images/nul1org_10th_2.jpeg' },
      { src: '/images/nul1org_10th_3.jpeg' },
      { src: '/images/nul1org_10th_4.jpeg' },
      { src: '/images/nul1org_10th_5.jpeg' },
      { src: '/images/nul1org_10th_6.jpeg' },
      { src: '/images/nul1org_10th_7.jpeg' },
      { src: '/images/nul1org_10th_8.jpeg' },
      { src: '/images/nul1org_10th_9.jpeg' },

      { src: '/images/nul1org_9th_1.jpeg' },
      { src: '/images/nul1org_9th_2.jpeg' },
      { src: '/images/nul1org_9th_3.jpeg' },
      { src: '/images/nul1org_9th_4.jpeg' },
      { src: '/images/nul1org_9th_5.jpeg' },
      { src: '/images/nul1org_9th_6.jpeg' },
      { src: '/images/nul1org_9th_7.jpeg' },
      { src: '/images/nul1org_9th_8.jpeg' },
      { src: '/images/nul1org_9th_9.jpeg' },
      { src: '/images/nul1org_9th_10.jpeg' },

      { src: '/images/nul1org_8th_1.jpeg' },
      { src: '/images/nul1org_8th_2.jpeg' },
      { src: '/images/nul1org_8th_3.jpeg' },
      { src: '/images/nul1org_8th_4.jpeg' },
      { src: '/images/nul1org_8th_5.jpeg' },
      { src: '/images/nul1org_8th_6.jpeg' },
      { src: '/images/nul1org_8th_7.jpeg' },
      { src: '/images/nul1org_8th_8.jpeg' },
      { src: '/images/nul1org_8th_9.jpeg' },
      { src: '/images/nul1org_8th_10.jpeg' },

      { src: '/images/nul1org_7th_1.jpeg' },
      { src: '/images/nul1org_7th_2.jpeg' },
      { src: '/images/nul1org_7th_3.jpeg' },
      { src: '/images/nul1org_7th_4.jpeg' },
      { src: '/images/nul1org_7th_5.jpeg' },
      { src: '/images/nul1org_7th_6.jpeg' },
      { src: '/images/nul1org_7th_7.jpeg' },
      { src: '/images/nul1org_7th_8.jpeg' },
      { src: '/images/nul1org_7th_9.jpeg' },

      { src: '/images/nul1org_4th_1.jpeg' },
      { src: '/images/nul1org_4th_2.jpeg' },
      { src: '/images/nul1org_4th_3.jpeg' },
      { src: '/images/nul1org_4th_4.jpeg' },
      { src: '/images/nul1org_4th_5.jpeg' },
      { src: '/images/nul1org_4th_6.jpeg' },
      { src: '/images/nul1org_4th_7.jpeg' },

      { src: '/images/nul1org_11th_1.jpeg' },
      { src: '/images/nul1org_11th_2.jpeg' },
      { src: '/images/nul1org_11th_3.jpeg' },
      { src: '/images/nul1org_11th_4.jpeg' },
      { src: '/images/nul1org_11th_5.jpeg' },
      { src: '/images/nul1org_11th_6.jpeg' },
      { src: '/images/nul1org_11th_7.jpeg' },
      { src: '/images/nul1org_11th_8.jpeg' },
      { src: '/images/nul1org_11th_9.jpeg' },
      { src: '/images/nul1org_11th_10.jpeg' },

      { src: '/images/nul1org_12th_1.jpeg' },
      { src: '/images/nul1org_12th_2.jpeg' },
      { src: '/images/nul1org_12th_3.jpeg' },
      { src: '/images/nul1org_12th_4.jpeg' },
      { src: '/images/nul1org_12th_5.jpeg' },
      { src: '/images/nul1org_12th_6.jpeg' },
      { src: '/images/nul1org_12th_7.jpeg' },
      { src: '/images/nul1org_12th_8.jpeg' },
      { src: '/images/nul1org_12th_9.jpeg' },
      { src: '/images/nul1org_12th_10.jpeg' },

      { src: '/images/nul1org_13th_1.jpeg' },
      { src: '/images/nul1org_13th_2.jpeg' },
      { src: '/images/nul1org_13th_3.jpeg' },
      { src: '/images/nul1org_13th_4.jpeg' },
      { src: '/images/nul1org_13th_5.jpeg' },
      { src: '/images/nul1org_13th_6.jpeg' },
      { src: '/images/nul1org_13th_7.jpeg' },
      { src: '/images/nul1org_13th_8.jpeg' },

      { src: '/images/nul1org_14th_lookbook1.jpeg' },
      { src: '/images/nul1org_14th_lookbook2.jpeg' },
      { src: '/images/nul1org_14th_lookbook3.jpeg' },
      { src: '/images/nul1org_14th_lookbook4.jpeg' },
      { src: '/images/nul1org_14th_lookbook5.jpeg' },
      { src: '/images/nul1org_14th_lookbook6.jpeg' },
      { src: '/images/nul1org_14th_lookbook7.jpeg' },
      { src: '/images/nul1org_14th_lookbook8.jpeg' },
      { src: '/images/nul1org_14th_lookbook9.jpeg' },
      { src: '/images/nul1org_14th_lookbook10.jpeg' },

      { src: '/images/nul1org_15th_Lookbook1.jpeg' },
      { src: '/images/nul1org_15th_Lookbook2.jpeg' },
      { src: '/images/nul1org_15th_Lookbook3.jpeg' },
      { src: '/images/nul1org_15th_Lookbook4.jpeg' },
      { src: '/images/nul1org_15th_Lookbook5.jpeg' },
      { src: '/images/nul1org_15th_Lookbook6.jpeg' },
      { src: '/images/nul1org_15th_Lookbook7.jpeg' },
      { src: '/images/nul1org_15th_Lookbook8.jpeg' },
      { src: '/images/nul1org_15th_Lookbook9.jpeg' },
      { src: '/images/nul1org_15th_Lookbook10.jpeg' },

      { src: '/images/JohnnyTerror_Lookbook1.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook2.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook3.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook4.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook5.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook6.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook7.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook8.jpeg' },
      { src: '/images/JohnnyTerror_Lookbook9.jpeg' },

      { src: '/images/nul1org_16th_Lookbook1.jpeg' },
      { src: '/images/nul1org_16th_Lookbook2.jpeg' },
      { src: '/images/nul1org_16th_Lookbook3.jpeg' },
      { src: '/images/nul1org_16th_Lookbook4.jpeg' },
      { src: '/images/nul1org_16th_Lookbook5.jpeg' },
      { src: '/images/nul1org_16th_Lookbook6.jpeg' },
      { src: '/images/nul1org_16th_Lookbook7.jpeg' },
      { src: '/images/nul1org_16th_Lookbook8.jpeg' },

      { src: '/images/nul1.org_eggtall_Lookbook1.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook2.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook3.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook4.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook5.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook6.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook7.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook8.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook9.jpeg' },
      { src: '/images/nul1.org_eggtall_Lookbook10.jpeg' },

      { src: '/images/nul1.org_cs_Lookbook1.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook2.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook3.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook4.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook5.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook6.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook7.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook8.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook9.jpeg' },
      { src: '/images/nul1.org_cs_Lookbook10.jpeg' },

      { src: '/images/nul1.org_16th_Lookbook1.jpeg' },
      { src: '/images/nul1.org_16th_Lookbook2.jpeg' },
      { src: '/images/nul1.org_16th_Lookbook3.jpeg' },
      { src: '/images/nul1.org_16th_Lookbook4.jpeg' },
      { src: '/images/nul1.org_16th_Lookbook5.jpeg' },
      { src: '/images/nul1.org_16th_Lookbook6.jpeg' },
      { src: '/images/nul1.org_16th_Lookbook7.jpeg' },
      { src: '/images/nul1.org_16th_Lookbook8.jpeg' },

      { src: '/images/nul1.org_17th_Lookbook1.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook2.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook3.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook4.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook5.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook6.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook7.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook8.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook9.jpeg' },
      { src: '/images/nul1.org_17th_Lookbook10.jpeg' }
    ])


    
    // 減少空白項目的比例以提高視覺密度
    const emptyItemCount = Math.floor(baseImages.length * 0.2);
    const emptyItems = Array(emptyItemCount).fill(null).map(getEmptyItem);
    
    return shuffleArray([...baseImages, ...emptyItems]);
  }, []);

  // 為行動版優化的 WebP 圖片
  const imageWebP = useMemo(() => {
    const baseImages = shuffleArray([
      { src: '/images_webp/nul1org_10th_1.webp' },
      { src: '/images_webp/nul1org_10th_2.webp' },
      { src: '/images_webp/nul1org_10th_3.webp' },
      { src: '/images_webp/nul1org_10th_4.webp' },
      { src: '/images_webp/nul1org_10th_5.webp' },
      { src: '/images_webp/nul1org_10th_6.webp' },
      { src: '/images_webp/nul1org_10th_7.webp' },
      { src: '/images_webp/nul1org_10th_8.webp' },
      { src: '/images_webp/nul1org_10th_9.webp' },

      { src: '/images_webp/nul1org_9th_1.webp' },
      { src: '/images_webp/nul1org_9th_2.webp' },
      { src: '/images_webp/nul1org_9th_3.webp' },
      { src: '/images_webp/nul1org_9th_4.webp' },
      { src: '/images_webp/nul1org_9th_5.webp' },
      { src: '/images_webp/nul1org_9th_6.webp' },
      { src: '/images_webp/nul1org_9th_7.webp' },
      { src: '/images_webp/nul1org_9th_8.webp' },
      { src: '/images_webp/nul1org_9th_9.webp' },
      { src: '/images_webp/nul1org_9th_10.webp' },

      { src: '/images_webp/nul1org_8th_1.webp' },
      { src: '/images_webp/nul1org_8th_2.webp' },
      { src: '/images_webp/nul1org_8th_3.webp' },
      { src: '/images_webp/nul1org_8th_4.webp' },
      { src: '/images_webp/nul1org_8th_5.webp' },
      { src: '/images_webp/nul1org_8th_6.webp' },
      { src: '/images_webp/nul1org_8th_7.webp' },
      { src: '/images_webp/nul1org_8th_8.webp' },
      { src: '/images_webp/nul1org_8th_9.webp' },
      { src: '/images_webp/nul1org_8th_10.webp' },

      { src: '/images_webp/nul1org_7th_1.webp' },
      { src: '/images_webp/nul1org_7th_2.webp' },
      { src: '/images_webp/nul1org_7th_3.webp' },
      { src: '/images_webp/nul1org_7th_4.webp' },
      { src: '/images_webp/nul1org_7th_5.webp' },
      { src: '/images_webp/nul1org_7th_6.webp' },
      { src: '/images_webp/nul1org_7th_7.webp' },
      { src: '/images_webp/nul1org_7th_8.webp' },
      { src: '/images_webp/nul1org_7th_9.webp' },

      { src: '/images_webp/nul1org_4th_1.webp' },
      { src: '/images_webp/nul1org_4th_2.webp' },
      { src: '/images_webp/nul1org_4th_3.webp' },
      { src: '/images_webp/nul1org_4th_4.webp' },
      { src: '/images_webp/nul1org_4th_5.webp' },
      { src: '/images_webp/nul1org_4th_6.webp' },
      { src: '/images_webp/nul1org_4th_7.webp' },

      { src: '/images_webp/nul1org_11th_1.webp' },
      { src: '/images_webp/nul1org_11th_2.webp' },
      { src: '/images_webp/nul1org_11th_3.webp' },
      { src: '/images_webp/nul1org_11th_4.webp' },
      { src: '/images_webp/nul1org_11th_5.webp' },
      { src: '/images_webp/nul1org_11th_6.webp' },
      { src: '/images_webp/nul1org_11th_7.webp' },
      { src: '/images_webp/nul1org_11th_8.webp' },
      { src: '/images_webp/nul1org_11th_9.webp' },
      { src: '/images_webp/nul1org_11th_10.webp' },

      { src: '/images_webp/nul1org_12th_1.webp' },
      { src: '/images_webp/nul1org_12th_2.webp' },
      { src: '/images_webp/nul1org_12th_3.webp' },
      { src: '/images_webp/nul1org_12th_4.webp' },
      { src: '/images_webp/nul1org_12th_5.webp' },
      { src: '/images_webp/nul1org_12th_6.webp' },
      { src: '/images_webp/nul1org_12th_7.webp' },
      { src: '/images_webp/nul1org_12th_8.webp' },
      { src: '/images_webp/nul1org_12th_9.webp' },
      { src: '/images_webp/nul1org_12th_10.webp' },

      { src: '/images_webp/nul1org_13th_1.webp' },
      { src: '/images_webp/nul1org_13th_2.webp' },
      { src: '/images_webp/nul1org_13th_3.webp' },
      { src: '/images_webp/nul1org_13th_4.webp' },
      { src: '/images_webp/nul1org_13th_5.webp' },
      { src: '/images_webp/nul1org_13th_6.webp' },
      { src: '/images_webp/nul1org_13th_7.webp' },
      { src: '/images_webp/nul1org_13th_8.webp' },

      { src: '/images_webp/nul1org_14th_lookbook1.webp' },
      { src: '/images_webp/nul1org_14th_lookbook2.webp' },
      { src: '/images_webp/nul1org_14th_lookbook3.webp' },
      { src: '/images_webp/nul1org_14th_lookbook4.webp' },
      { src: '/images_webp/nul1org_14th_lookbook5.webp' },
      { src: '/images_webp/nul1org_14th_lookbook6.webp' },
      { src: '/images_webp/nul1org_14th_lookbook7.webp' },
      { src: '/images_webp/nul1org_14th_lookbook8.webp' },
      { src: '/images_webp/nul1org_14th_lookbook9.webp' },
      { src: '/images_webp/nul1org_14th_lookbook10.webp' },

      { src: '/images_webp/nul1org_15th_Lookbook1.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook2.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook3.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook4.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook5.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook6.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook7.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook8.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook9.webp' },
      { src: '/images_webp/nul1org_15th_Lookbook10.webp' },

      { src: '/images_webp/JohnnyTerror_Lookbook1.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook2.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook3.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook4.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook5.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook6.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook7.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook8.webp' },
      { src: '/images_webp/JohnnyTerror_Lookbook9.webp' },

      { src: '/images_webp/nul1org_16th_Lookbook1.webp' },
      { src: '/images_webp/nul1org_16th_Lookbook2.webp' },
      { src: '/images_webp/nul1org_16th_Lookbook3.webp' },
      { src: '/images_webp/nul1org_16th_Lookbook4.webp' },
      { src: '/images_webp/nul1org_16th_Lookbook5.webp' },
      { src: '/images_webp/nul1org_16th_Lookbook6.webp' },
      { src: '/images_webp/nul1org_16th_Lookbook7.webp' },
      { src: '/images_webp/nul1org_16th_Lookbook8.webp' },

      { src: '/images_webp/nul1.org_eggtall_Lookbook1.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook2.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook3.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook4.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook5.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook6.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook7.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook8.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook9.webp' },
      { src: '/images_webp/nul1.org_eggtall_Lookbook10.webp' },

      { src: '/images_webp/nul1.org_cs_Lookbook1.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook2.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook3.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook4.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook5.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook6.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook7.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook8.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook9.webp' },
      { src: '/images_webp/nul1.org_cs_Lookbook10.webp' },

      { src: '/images_webp/nul1.org_16th_Lookbook1.webp' },
      { src: '/images_webp/nul1.org_16th_Lookbook2.webp' },
      { src: '/images_webp/nul1.org_16th_Lookbook3.webp' },
      { src: '/images_webp/nul1.org_16th_Lookbook4.webp' },
      { src: '/images_webp/nul1.org_16th_Lookbook5.webp' },
      { src: '/images_webp/nul1.org_16th_Lookbook6.webp' },
      { src: '/images_webp/nul1.org_16th_Lookbook7.webp' },
      { src: '/images_webp/nul1.org_16th_Lookbook8.webp' },

      { src: '/images_webp/nul1.org_17th_Lookbook1.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook2.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook3.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook4.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook5.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook6.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook7.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook8.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook9.webp' },
      { src: '/images_webp/nul1.org_17th_Lookbook10.webp' },
    ])
    
    const emptyItemCount = Math.floor(baseImages.length * 0.2);
    const emptyItems = Array(emptyItemCount).fill(null).map(getEmptyItem);
    
    return shuffleArray([...baseImages, ...emptyItems]);
  }, []);


  const MobileGrid = ({ images = [] }) => {
    const [visibleImages, setVisibleImages] = useState([]);
    const [page, setPage] = useState(1);
    const imagesPerPage = 10;
  
    const { ref: bottomRef, inView } = useInView({
      threshold: 0,
      rootMargin: '200px 0px',
    });
  
    useEffect(() => {
      if (!Array.isArray(images)) {
        console.warn('images prop is not an array');
        return;
      }
      const start = 0;
      const end = page * imagesPerPage;
      const newImages = images.slice(start, end).filter(item => !item.isEmpty && item.src);
      setVisibleImages(newImages);
    }, [page, images]);
  
    useEffect(() => {
      if (inView && Array.isArray(images) && visibleImages.length < images.length) {
        setPage(prev => prev + 1);
      }
    }, [inView, images, visibleImages.length]);
  
    const ImageComponent = ({ src, index }) => {
      const [loaded, setLoaded] = useState(false);
      const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '50px 0px',
      });
  
      return (
        <div 
          ref={ref}
          className="w-full flex justify-center mb-4"
        >
          {inView && (
            <div className="relative w-full">
              <LazyLoadImage
                src={src}
                alt=""
                className={`w-full h-auto object-contain transition-opacity duration-300 ${
                  loaded ? 'opacity-100' : 'opacity-0'
                }`}
                style={{ 
                  aspectRatio: '500/626',
                  maxWidth: '100%',
                  width: 'auto'
                }}
                loading="lazy"
                onLoad={() => setLoaded(true)}
                decoding="async"
              />
              {!loaded && (
                <div className="absolute inset-0 bg-gray-900 animate-pulse" 
                  style={{ aspectRatio: '500/626' }}
                />
              )}
            </div>
          )}
        </div>
      );
    };
  
    return (
      <div className="grid grid-cols-1 gap-4 px-4">
        {visibleImages.map((item, index) => (
          <ImageComponent 
            key={item.src} 
            src={item.src}
            index={index}
          />
        ))}
        <div ref={bottomRef} className="h-4" />
      </div>
    );
  };

  const DesktopGrid = () => {
    const calculateColumnWidth = () => {
      const viewportWidth = window.innerWidth;
      const minColumns = 2;
      const maxColumns = 4;
      const targetWidth = Math.floor(viewportWidth / minColumns);
      return Math.min(Math.max(targetWidth, 300), 500);
    };
  
    const calculateImageSize = (index) => {
      const baseWidth = [60, 70, 80, 90, 100];
      const randomWidth = baseWidth[Math.floor(index % baseWidth.length)];
      return `${randomWidth}%`;
    };
  
    const getOptimizedAlignment = (index) => {
      const alignments = ['flex-start', 'center', 'flex-end'];
      return alignments[index % alignments.length];
    };
  
    return (
      <StackGrid
        monitorImagesLoaded
        columnWidth={calculateColumnWidth()}
        gutterWidth={20}
        gutterHeight={40}
        appear={transition.appear}
        appeared={transition.appeared}
        enter={transition.enter}
        entered={transition.entered}
        leaved={transition.leaved}
        duration={600}
        easing="cubic-bezier(0.4, 0, 0.2, 1)"
      >          
        {images.map((item, index) => (      
          <figure
            ref={(el) => gridItemsRef.current[index] = el}
            key={item.id || index} 
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: getOptimizedAlignment(index),
              alignItems: getOptimizedAlignment((index + 1) % 3), 
              width: '100%',
              height: item.isEmpty ? item.height : 'auto',
              margin: 0,
              padding: 0,
              transition: 'opacity 0.3s ease' 
            }}
          >
            {!item.isEmpty && item.src && (
              <EmergingImage
                key={item.src}
                url={item.src}
                type={0}
                className="grid__item-img-inner"
                style={{
                  width: calculateImageSize(index),
                  height: 'auto',
                  objectFit: 'contain',
                  aspectRatio: '500/626',
                  willChange: 'transform',
                  transform: 'translateZ(0)'
                }}
              />
            )}
          </figure>
        ))}
      </StackGrid>
    );
  };

  return (
    <div className="absolute top-0 left-0 flex flex-col justify-start w-screen h-screen overflow-x-hidden overflow-y-auto bg-black px-4 md:px-8">
      <div className="mt-20">
        <Scene />
        {isDesktop ? <DesktopGrid /> : <MobileGrid images={imageWebP} />}
      </div>
    </div>
  );
};

export default Archive;