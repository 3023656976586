import { createRoot } from 'react-dom/client'
import './index.css'
import { Logo } from '@pmndrs/branding'
import { App } from './Home'
import ReactPlayer from 'react-player'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Archive from './Archive'
import Home from './Home'
import { useMediaQuery } from 'react-responsive';

function Root() {

  const isDesktop = useMediaQuery({
    query: '(min-width: 640px)',
  });

  return (
    <>
       <div 
    className="absolute top-0 left-0 w-screen h-screen bg-cover bg-center bg-no-repeat"
    style={{
      backgroundImage: isDesktop ? 'none' : "url('/bg-mobile.jpeg')"
    }}
  >
      <div className="fixed bottom-5 right-5 z-50">
        <img width={'100px'} src="/work_.png"></img>
      </div>
      <div className="w-full h-full">
        <Router className="w-full h-full">
          <div className="w-full h-full">
            <div className="fixed top-5 font-semibold z-50 flex flex-row justify-center items-center w-screen gap-10">
              <div className="relative">
                <Link className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none" to="/">
                  home
                </Link>
              </div>
              <div className="relative">
                <Link
                  className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none"
                  to="https://www.4dimensionapparel.com/categories/nul1org"
                  target="_blank"
                  rel="noopener noreferrer">
                  shop
                </Link>
              </div>
              <div className="relative">
                <Link className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none" to="/archive">
                  archive
                </Link>
              </div>
              <div className="relative">
                <Link
                  className="text-black transition duration-300 hover:text-white text-shadow-md hover:text-shadow-none"
                  to="https://www.instagram.com/nul1org/"
                  target="_blank"
                  rel="noopener noreferrer">
                  instagram
                </Link>
              </div>
            </div>
            <Routes className="w-full h-full">
              <Route path="/" element={<Home />} />
              <Route path="/archive" element={<Archive />} />
            </Routes>
          </div>
        </Router>
      </div>
      </div>
    </>
  )
}

createRoot(document.getElementById('root')).render(<Root />)